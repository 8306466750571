import React from 'react';

const Winter = () => {
  return (
    <div className="tile is-ancestor">
      <div className="m-6">
        <div className="my-4 text-center">
          <h2 className="font-headline my-2">concrete</h2>
        </div>

        <div className="mb-10 px-6 mx-auto">
          <ol className="md:columns-2 md:pl-6">
            <li className="mb-4">
              <strong>prep form</strong>
              <ul>
                <li>clean existing / old concrete</li>
                <li>apply Silicon caulk</li>
                <li>apply form release</li>
              </ul>
            </li>
            <li className="mb-4">
              <strong>mix concrete</strong>
              <ul>
                <li>read directions!</li>
                <li>initially add x/y of total clean water content</li>
                <li>slump test</li>
              </ul>
            </li>
            <li className="mb-4 break-inside-avoid">
              <strong>place concrete</strong>
              <ul>
                <li>Spread & Consolidate</li>
                <li>Tamping & Taping</li>
                <li>Screed w/ clean Straight 2x4</li>
              </ul>
            </li>
            <li className="mb-4 md:ml-8">
              <strong>float</strong>
              <ul>
                <li>
                  use magnesium or wood
                  <ul className="ml-4">
                    <li>this will surface open</li>
                  </ul>
                </li>
                <li>consolidate edges with edger</li>
              </ul>
            </li>
            <li className="mb-4 md:ml-8">
              <strong>WAIT</strong>
              <ul>
                <li>For bleed unter to evaporte 75%</li>
              </ul>
            </li>
            <li className="mb-4 md:ml-8">
              <strong>finish</strong>
              <ul>
                <li>
                  test with finger
                  <ul className="ml-4">
                    <li>should not compress more than 1/8"</li>
                  </ul>
                </li>
                <li>
                  steel trowel
                  <ul className="ml-4">
                    <li>Close surface</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
        </div>

        {/* paver */}
        <div>
          <div className="my-4">
            <h3 className="font-headline my-2">paver</h3>
            <hr className="my-2" />
          </div>
          <div className="flex items-center">
            <article className="tile is-child my-2 mr-2">
              <img src="/img/wtc/concrete/paver2.jpeg" alt="" />
            </article>
            <article className="tile is-child m-2" style={{ flexGrow: '2' }}>
              <img src="/img/wtc/concrete/paver.jpeg" alt="" />
            </article>
          </div>
          <div className="tile my-2">
            <img src="/img/wtc/concrete/paver_peace.jpeg" alt="" />
          </div>
        </div>

        {/* two step */}
        <div className="mt-10">
          <div className="my-4">
            <h3 className="font-headline my-2">two step</h3>
            <hr className="my-2" />
          </div>
          <div className="tile my-2">
            <img src="/img/wtc/concrete/2step_sides.jpeg" alt="" />
          </div>
          <div className="flex items-center">
            <article className="tile is-child my-2 mr-2">
              <img src="/img/wtc/concrete/2step_form2.jpeg" alt="" />
            </article>
            <article className="tile is-child m-2">
              <img src="/img/wtc/concrete/2step_empty_back.jpeg" alt="" />
            </article>
            <article className="tile is-child my-2 ml-2">
              <img src="/img/wtc/concrete/2step_form.jpeg" alt="" />
            </article>
          </div>

          <div className="py-4">
            <article className="tile is-child">
              <img src="/img/wtc/concrete/2step_concrete.jpeg" alt="" />
            </article>
          </div>

          <div className="flex">
            <article className="tile is-child">
              <img src="/img/wtc/concrete/2step_wet.jpeg" alt="" />
            </article>
          </div>

          <div className="flex items-center">
            <article className="tile is-child my-2 mr-2">
              <img src="/img/wtc/concrete/2step_one.jpeg" alt="" />
            </article>
            <article className="tile is-child my-2">
              <img src="/img/wtc/concrete/2step_work.jpeg" alt="" />
            </article>
          </div>

          <div className="flex">
            <article className="tile is-child my-2 mr-2">
              <img src="/img/wtc/concrete/2step_work3.jpeg" alt="" />
            </article>
            <article className="tile is-child my-2 ml-2">
              <img src="/img/wtc/concrete/2step_work2.jpeg" alt="" />
            </article>
          </div>

          <div className="flex items-center">
            <div className="tile flex flex-col items-center is-vertical pr-4">
              <article className="tile is-child">
                <img src="/img/wtc/concrete/2step_done.jpeg" alt="" />
              </article>

              <article className="py-4 tile is-child">
                <img src="/img/wtc/concrete/2step_stand.jpeg" alt="" />
              </article>
            </div>
            <div className="tile is-parent" style={{ flexGrow: '2' }}>
              <article className="py-4 tile is-child">
                <img src="/img/wtc/concrete/2step_stand_final.jpeg" alt="" />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Winter;
